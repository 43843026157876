<template>
  <div>
    <Navigation />
    <v-container class="admin-dashboard">
      <h1 class="text-h4 font-weight-bold mb-6">Admin Dashboard</h1>
      
      <v-row>
        <!-- Stats Overview -->
        <v-col cols="6" md="6" lg="6">
          <v-card elevation="2" class="pa-4">
            <h2 class="text-h6 font-weight-bold mb-4">Overview</h2>
            <v-list>
              <v-list-item>
                <v-list-item-title class="text-grey">Total Users</v-list-item-title>
                <v-list-item-subtitle class="text-h5 font-weight-bold">{{ stats.totalUsers }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-grey">Active Users (Last 7 Days)</v-list-item-title>
                <v-list-item-subtitle class="text-h5 font-weight-bold">{{ stats.activeUsers }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="text-grey">Total Points Given</v-list-item-title>
                <v-list-item-subtitle class="text-h5 font-weight-bold">{{ stats.totalPoints }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <!-- Recent Activity -->
        <v-col cols="6" md="6" lg="6">
          <v-card elevation="2" class="pa-4">
            <h2 class="text-h6 font-weight-bold mb-4">Recent Activity</h2>
            <v-list v-if="recentActivity.length > 0">
              <v-list-item v-for="activity in recentActivity" :key="activity.id">
                <v-list-item-title>{{ activity.description }}</v-list-item-title>
                <v-list-item-subtitle class="text-grey">{{ activity.timestamp }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <p v-else class="text-grey text-center pa-4">No recent activity</p>
          </v-card>
        </v-col>

        <!-- Store Management -->
        <v-col cols="12" md="12" lg="12">
          <v-card elevation="2" class="pa-4">
            <div class="d-flex justify-space-between align-center mb-4">
              <div>
                <h2 class="text-h6 font-weight-bold">Rewards</h2>
                <span class="text-body-2 text-medium-emphasis">Manage your store rewards</span>
              </div>
              <v-btn
                color="primary"
                prepend-icon="mdi-plus"
                @click="openStoreItemModal"
              >
                Create Reward
              </v-btn>
            </div>

            <v-row>
              <v-col
                v-for="item in storeItems"
                :key="item.id"
                cols="12"
                sm="12"
                md="6"
              >
                <v-card
                  elevation="1"
                  class="h-100"
                  rounded="lg"
                  :hover="true"
                >
                  <v-img
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                    :alt="item.name"
                    height="200"
                    cover
                    class="bg-grey-lighten-2"
                  ></v-img>
                  <v-img
                    v-else
                    height="200"
                    cover
                    class="bg-grey-lighten-2"
                  >
                    <div class="d-flex align-center justify-center fill-height">
                      <v-icon
                        size="48"
                        color="grey-lighten-1"
                        icon="mdi-image-outline"
                      ></v-icon>
                    </div>
                  </v-img>

                  <v-card-title class="d-flex justify-space-between align-center pt-4 px-4">
                    <span class="text-truncate">{{ item.name }}</span>
                    <div class="d-flex">
                      <v-btn
                        icon="mdi-pencil"
                        size="small"
                        variant="text"
                        color="primary"
                        class="m  r-1"
                        @click="editStoreItem(item)"
                      ></v-btn>
                      <v-btn
                        icon="mdi-delete"
                        size="small"
                        variant="text"
                        color="error"
                        @click="deleteStoreItem(item.id)"
                      ></v-btn>
                    </div>
                  </v-card-title>

                  <v-card-text class="pt-2">
                    <p class="text-body-2 text-medium-emphasis mb-3 text-truncate-2-lines">
                      {{ item.description || 'No description' }}
                    </p>
                    <div class="d-flex justify-space-between align-center">
                      <v-chip
                        color="primary"
                        variant="flat"
                        size="small"
                        class="font-weight-medium"
                      >
                        <template v-slot:prepend>
                          <span class="mr-1">🙏</span>
                        </template>
                        {{ item.points }}
                      </v-chip>
                      <v-chip
                        color="grey"
                        variant="flat"
                        size="small"
                        class="font-weight-medium"
                      >
                        {{ item.quantity }} available
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Empty State -->
              <v-col v-if="storeItems.length === 0" cols="12" class="d-flex justify-center">
                <v-card 
                  class="text-center pa-8"
                  elevation="2"
                  width="100%"
                >
                  <v-sheet
                    color="grey-lighten-3"
                    width="80"
                    height="80"
                    class="mx-auto rounded-circle d-flex align-center justify-center mb-4"
                  >
                  <span class="mr-1">🙏</span>
                  </v-sheet>
                  <h3 class="text-h6 font-weight-medium mb-2">No rewards yet</h3>
                  <p class="text-body-2 text-medium-emphasis mb-6">
                    Get started by creating your first reward
                  </p>
                  <v-btn
                    color="primary"
                    prepend-icon="mdi-plus"
                    @click="openStoreItemModal"
                  >
                    Create First Reward
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Store Item Modal -->
      <v-dialog
        v-model="showStoreItemModal"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="text-h6 pa-4">
            {{ editingItem ? 'Edit' : 'Create' }} Reward
          </v-card-title>
          
          <v-divider></v-divider>

          <v-card-text class="pa-4">
            <v-form @submit.prevent="saveStoreItem">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="storeItemForm.name"
                      label="Name"
                      required
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="storeItemForm.description"
                      label="Description"
                      variant="outlined"
                      density="comfortable"
                      rows="3"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model.number="storeItemForm.points"
                      label="Points"
                      type="number"
                      required
                      min="0"
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model.number="storeItemForm.quantity"
                      label="Quantity"
                      type="number"
                      required
                      min="0"
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="storeItemForm.imageUrl"
                      label="Image URL"
                      type="url"
                      variant="outlined"
                      density="comfortable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              variant="text"
              @click="closeStoreItemModal"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="saveStoreItem"
            >
              {{ editingItem ? 'Save Changes' : 'Create Reward' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Navigation from '@/components/Navigation.vue'

const router = useRouter()
const stats = ref({
  totalUsers: 0,
  activeUsers: 0,
  totalPoints: 0
})
const recentActivity = ref([])
const storeItems = ref([])
const showStoreItemModal = ref(false)
const editingItem = ref(null)
const defaultStoreItem = {
  name: '',
  description: '',
  points: 0,
  quantity: 0,
  imageUrl: '',
  office: '',
  contributors: [],
  progress: undefined
}
const storeItemForm = ref({ ...defaultStoreItem })

onMounted(async () => {
  try {
    // Check if user is admin
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/user/is-admin`, {
      credentials: 'include'
    })
    if (!response.ok) {
      router.push('/dashboard')
      return
    }

    // Fetch admin dashboard data
    await Promise.all([
      fetchStats(),
      fetchRecentActivity(),
      fetchStoreItems()
    ])
  } catch (error) {
    console.error('Error loading admin dashboard:', error)
  }
})

const fetchStats = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/admin/stats`, {
      credentials: 'include'
    })
    if (response.ok) {
      stats.value = await response.json()
    }
  } catch (error) {
    console.error('Error fetching stats:', error)
  }
}

const fetchRecentActivity = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/admin/activity`, {
      credentials: 'include'
    })
    if (response.ok) {
      recentActivity.value = await response.json()
    }
  } catch (error) {
    console.error('Error fetching recent activity:', error)
  }
}

const fetchStoreItems = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/store/items`, {
      credentials: 'include'
    })
    if (response.ok) {
      storeItems.value = await response.json()
    }
  } catch (error) {
    console.error('Error fetching store items:', error)
  }
}

function openStoreItemModal() {
  editingItem.value = null
  storeItemForm.value = { ...defaultStoreItem }
  showStoreItemModal.value = true
}

function editStoreItem(item) {
  editingItem.value = item
  storeItemForm.value = { 
    ...item,
    contributors: [...(item.contributors || [])],
  }
  showStoreItemModal.value = true
}

function closeStoreItemModal() {
  showStoreItemModal.value = false
  editingItem.value = null
}

function saveStoreItem() {
  try {
    const url = `${process.env.VUE_APP_API_BASE_URL}/store/items${editingItem.value ? `/${editingItem.value.id}` : ''}`
    const method = editingItem.value ? 'PUT' : 'POST'
    
    const response = fetch(url, {
      method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(storeItemForm.value)
    })

    if (!response.ok) {
      const error = response.text()
      throw new Error(error || 'Failed to save store item')
    }

    fetchStoreItems()
    closeStoreItemModal()
  } catch (error) {
    console.error('Error saving store item:', error)
    alert('Failed to save store item: ' + error.message)
  }
}

function deleteStoreItem(itemId) {
  if (!confirm('Are you sure you want to delete this item?')) return

  try {
    const response = fetch(`${process.env.VUE_APP_API_BASE_URL}/store/items/${itemId}`, {
      method: 'DELETE',
      credentials: 'include'
    })

    if (response.ok) {
      fetchStoreItems()
    }
  } catch (error) {
    console.error('Error deleting store item:', error)
  }
}
</script>

<style scoped>
.admin-dashboard {
  padding: 2rem;
  background-color: #f3f4f6;
  min-height: calc(100vh - 64px);  /* Adjust for navigation height */
}
</style>
