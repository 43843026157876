import { createApp } from "vue";
import App from "./App.vue";
import Home from "./Home.vue";
import InstallSuccess from "./InstallSuccess.vue";
import SignIn from "./SignIn.vue";
import Leaderboard from "./Leaderboard.vue";
import Dashboard from "./Dashboard.vue";
import RewardsStore from "./RewardsStore.vue";
import Admin from "./Admin.vue";
import {
  createWebHistory,
  createRouter,
  createWebHashHistory,
} from "vue-router";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

const routes = [
  { path: "/", component: Home },
  { path: "/install-success", component: InstallSuccess },
  { path: "/sign-in", component: SignIn },
  { path: "/leaderboard", component: Leaderboard },
  { path: "/signin-success", redirect: "/dashboard"  },
  { path: "/dashboard", component: Dashboard },
  { path: "/rewards", component: RewardsStore },
  { 
    path: "/admin", 
    component: Admin,
    meta: { requiresAdmin: true }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
  // Routes that don't require authentication
  const publicRoutes = ['/', '/sign-in', '/install-success'];

  if (publicRoutes.includes(to.path)) {
    next();
    return;
  }

  // Add debug logging
  console.log('Checking authentication for:', to.path);
  
  try {
    // Use the ngrok URL for auth check
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/check-auth`, {
      credentials: 'include',
      headers: {
          'Accept': 'application/json'
      }
    }); 
    const data = await response.json();

    if (!data.isAuthenticated) {
      next('/sign-in');
      return;
    }

    // Check admin access for admin routes
    if (to.meta.requiresAdmin) {
      const adminResponse = await fetch(`${process.env.VUE_APP_API_BASE_URL}/user/is-admin`, {
        credentials: 'include'
      });
      const adminData = await adminResponse.json();
      
      if (!adminData.isAdmin) {
        next('/dashboard');
        return;
      }
    }

    next();
  } catch (error) {
    console.error('Auth check failed:', error);
    next('/sign-in');
  }
});

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.mount("#app");
