<template>
  <div>
    <Navigation />
    <v-container>
      <!-- User Points Section -->
      <v-card elevation="2" class="mb-6">
        <v-card-text class="d-flex justify-space-between align-center">
          <h2 class="text-h5 font-weight-bold">Your Points</h2>
          <div class="text-h6 text-primary font-weight-medium">
            <span class="mr-1">🙏</span>
            {{ userPoints }} points available
          </div>
        </v-card-text>
      </v-card>

      <!-- Store Section -->
      <v-card elevation="2">
        <v-card-title class="d-flex justify-space-between align-center pa-4">
          <div>
            <span class="text-h5 font-weight-bold">Rewards Store</span>
          </div>
          <v-btn
            v-if="isAdmin"
            color="primary"
            prepend-icon="mdi-plus"
            @click="showAddItemModal = true"
          >
            Add Item
          </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- Loading State -->
          <div v-if="loading" class="d-flex justify-center align-center py-8">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>

          <!-- Error State -->
          <v-alert
            v-else-if="error"
            type="error"
            class="my-4"
          >
            {{ error }}
          </v-alert>

          <!-- Store Items Grid -->
          <v-row v-else>
            <v-col
              v-for="item in storeItems"
              :key="item.id"
              cols="12"
              sm="12"
              md="6"
            >
              <v-card
                elevation="1"
                class="h-100"
                rounded="lg"
                :hover="true"
              >
                <v-img
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  :alt="item.name"
                  height="200"
                  cover
                  class="bg-grey-lighten-2"
                ></v-img>
                <v-img
                  v-else
                  height="200"
                  cover
                  class="bg-grey-lighten-2"
                >
                  <div class="d-flex align-center justify-center fill-height">
                    <v-icon
                      size="48"
                      color="grey-lighten-1"
                      icon="mdi-image-outline"
                    ></v-icon>
                  </div>
                </v-img>

                <v-card-title class="d-flex justify-space-between align-center pt-4 px-4">
                  <span class="text-truncate">{{ item.name }}</span>
                  <div class="d-flex" v-if="isAdmin">
                    <v-btn
                      icon="mdi-pencil"
                      size="small"
                      variant="text"
                      color="primary"
                      class="mr-1"
                      @click="editItem(item)"
                    ></v-btn>
                  </div>
                </v-card-title>

                <v-card-text class="pt-2">
                  <p class="text-body-2 text-medium-emphasis mb-3 text-truncate-2-lines">
                    {{ item.description }}
                  </p>
                  <div class="d-flex justify-space-between align-center">
                    <v-chip
                      color="primary"
                      variant="flat"
                      size="small"
                      class="font-weight-medium"
                    >
                      <template v-slot:prepend>
                        <span class="mr-1">🙏</span>
                      </template>
                      {{ item.points }}
                    </v-chip>
                    <v-chip
                      color="grey"
                      variant="flat"
                      size="small"
                      class="font-weight-medium"
                    >
                      {{ item.quantity }} available
                    </v-chip>
                  </div>
                  <v-btn
                    block
                    color="primary"
                    class="mt-4"
                    :disabled="userPoints < item.points || item.quantity === 0"
                    @click="redeemItem(item)"
                  >
                    Redeem
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Add/Edit Item Modal -->
      <v-dialog
        v-model="showAddItemModal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ editingItem ? 'Edit' : 'Add' }} Item</span>
          </v-card-title>

          <v-card-text>
            <v-form @submit.prevent="saveItem">
              <v-text-field
                v-model="itemForm.name"
                label="Name"
                required
              ></v-text-field>

              <v-textarea
                v-model="itemForm.description"
                label="Description"
                required
              ></v-textarea>

              <v-text-field
                v-model.number="itemForm.points"
                label="Points"
                type="number"
                required
                min="1"
              ></v-text-field>

              <v-text-field
                v-model.number="itemForm.quantity"
                label="Quantity"
                type="number"
                required
                min="0"
              ></v-text-field>

              <v-text-field
                v-model="itemForm.imageUrl"
                label="Image URL"
                type="url"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey-darken-1"
              variant="text"
              @click="showAddItemModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              @click="saveItem"
            >
              {{ editingItem ? 'Save' : 'Add' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation Modal -->
      <v-dialog
        v-model="showConfirmModal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Confirm Redemption</span>
          </v-card-title>

          <v-card-text>
            Are you sure you want to redeem {{ selectedItem?.name }} for {{ selectedItem?.points }} points?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey-darken-1"
              variant="text"
              @click="showConfirmModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              variant="flat"
              @click="confirmRedeem"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Navigation from './components/Navigation.vue'

interface StoreItem {
  id: string
  name: string
  description: string
  points: number
  quantity: number
  imageUrl?: string
}

const storeItems = ref<StoreItem[]>([])
const loading = ref(true)
const error = ref('')
const showAddItemModal = ref(false)
const showConfirmModal = ref(false)
const selectedItem = ref<StoreItem | null>(null)
const editingItem = ref<StoreItem | null>(null)
const userPoints = ref(0)
const isAdmin = ref(false)

const itemForm = ref({
  name: '',
  description: '',
  points: 0,
  quantity: 0,
  imageUrl: ''
})

onMounted(async () => {
  await Promise.all([
    fetchStoreItems(),
    fetchUserPoints(),
    checkAdminStatus()
  ])
})

async function fetchStoreItems() {
  loading.value = true
  error.value = ''
  
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/store/items`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to fetch store items')
    }
    
    storeItems.value = await response.json()
  } catch (e) {
    error.value = 'Failed to load store items. Please try again later.'
    console.error('Error fetching store items:', e)
  } finally {
    loading.value = false
  }
}

async function fetchUserPoints() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/my-stats`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to fetch user points')
    }
    
    const data = await response.json()
    userPoints.value = data.redeemablePoints
  } catch (e) {
    console.error('Error fetching user points:', e)
  }
}

async function checkAdminStatus() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/user/is-admin`,
      { credentials: 'include' }
    )
    
    if (!response.ok) {
      throw new Error('Failed to check admin status')
    }
    
    const data = await response.json()
    isAdmin.value = data.isAdmin
  } catch (e) {
    console.error('Error checking admin status:', e)
    isAdmin.value = false
  }
}

function editItem(item: StoreItem) {
  editingItem.value = item
  itemForm.value = { ...item }
  showAddItemModal.value = true
}

async function saveItem() {
  try {
    const endpoint = editingItem.value
      ? `/store/items/${editingItem.value.id}`
      : '/store/items'
    
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}${endpoint}`,
      {
        method: editingItem.value ? 'PUT' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(itemForm.value)
      }
    )
    
    if (!response.ok) {
      throw new Error('Failed to save item')
    }
    
    await fetchStoreItems()
    showAddItemModal.value = false
    editingItem.value = null
    itemForm.value = {
      name: '',
      description: '',
      points: 0,
      quantity: 0,
      imageUrl: ''
    }
  } catch (e) {
    console.error('Error saving item:', e)
    alert('Failed to save item. Please try again.')
  }
}

function redeemItem(item: StoreItem) {
  selectedItem.value = item
  showConfirmModal.value = true
}

async function confirmRedeem() {
  if (!selectedItem.value) return
  
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_BASE_URL}/store/redeem/${selectedItem.value.id}`,
      {
        method: 'POST',
        credentials: 'include'
      }
    )
    
    if (!response.ok) {
      throw new Error('Failed to redeem item')
    }
    
    await Promise.all([
      fetchStoreItems(),
      fetchUserPoints()
    ])
    showConfirmModal.value = false
    selectedItem.value = null
  } catch (e) {
    console.error('Error redeeming item:', e)
    alert('Failed to redeem item. Please try again.')
  }
}
</script>
