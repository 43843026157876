<template>
  <nav class="navigation">
    <div class="nav-brand">Prec8</div>
    <div class="nav-menu">
      <router-link v-if="isAdmin" to="/admin" class="nav-item">Admin</router-link>
      <router-link to="/dashboard" class="nav-item">Home</router-link>
      <router-link to="/leaderboard" class="nav-item">Leaderboard</router-link>
      <router-link to="/rewards" class="nav-item">Rewards Store</router-link>
      <a href="#" @click.prevent="signOut" class="nav-item">Sign Out</a>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const isAdmin = ref(false)

onMounted(async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/user/is-admin`, {
      credentials: 'include'
    })
    if (response.ok) {
      const data = await response.json()
      isAdmin.value = data.isAdmin
    }
  } catch (error) {
    console.error('Error checking admin status:', error)
  }
})

const signOut = async () => {
  try {
    try {
      await fetch(`${process.env.VUE_APP_API_BASE_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      })
    } catch (error) {
      console.warn('Server logout failed, falling back to client-side:', error)
    }

    document.cookie = 'session_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.ngrok-free.app; Secure; SameSite=None'
    document.cookie = 'session_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Secure; SameSite=None'
    
    router.push('/sign-in')
  } catch (error) {
    console.error('Error signing out:', error)
  }
}
</script>

<style scoped>
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.nav-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
}

.nav-menu {
  display: flex;
  gap: 1.5rem;
}

.nav-item {
  text-decoration: none;
  color: #666;
  font-weight: 500;
  transition: color 0.2s ease;
}

.nav-item:hover {
  color: #2c3e50;
}

.nav-item.router-link-active {
  color: #42b983;
  font-weight: 600;
}
</style>
